<template>
  <div class="app-container">
    <el-row>
      <el-col :span="4" style="border-right: none;box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;">
        <el-tree
          style="max-width: 600px"
          :data="treeDataList"
          node-key="id"
          default-expand-all
          :style="{height:contentStyleObj}"
        >
          <template #default="{ node, data }">
            <span class="custom-tree-node">
              <span v-if="node.label =='所有文件'" @click="clickType('sy')">
                <el-icon ><FolderOpened /></el-icon>
                {{ node.label }}
                （<span v-if="countloading">
                  <el-icon><Loading /></el-icon>
                </span>
                <span v-else>
                  {{ numAll }}
                </span>）
              </span>
              <span v-if="node.label =='发票'" @click="clickType('bill')">
                <el-icon ><FolderOpened /></el-icon>
                {{ node.label }}
                （<span v-if="countloading">
                  <el-icon><Loading /></el-icon>
                </span>
                <span v-else>
                  {{ countTemp.发票 }}
                </span>）
              </span>
              <span v-if="node.label =='银行'" @click="clickType('bank')">
                <el-icon ><FolderOpened /></el-icon>
                {{ node.label }}
                （<span v-if="countloading">
                  <el-icon><Loading /></el-icon>
                </span>
                <span v-else>
                  {{ countTemp.银行 }}
                </span>）
              </span>
              <span v-if="node.label =='薪酬'" @click="clickType('salary')">
                <el-icon ><FolderOpened /></el-icon>
                {{ node.label }}
                （<span v-if="countloading">
                  <el-icon><Loading /></el-icon>
                </span>
                <span v-else>
                  {{ countTemp.薪酬 }}
                </span>）
              </span>
              <span v-if="node.label =='其他'" @click="clickType('others')">
                <el-icon ><FolderOpened /></el-icon>
                {{ node.label }}
                （<span v-if="countloading">
                  <el-icon><Loading /></el-icon>
                </span>
                <span v-else>
                  {{ countTemp.其他 }}
                </span>）
              </span>
            </span>
          </template>
        </el-tree>
      </el-col>
      <el-col :span="20">
        <div class="top_select">
          <div>
            <el-input placeholder="请输入文件名搜索" v-model="listQuery.fileName" style="width:200px" size="small" clearable @keyup.enter="getList" />
            <el-button size="small" type="primary" icon="Search" @click="getList" style="margin-right: 5px;">搜索</el-button>
            <search @success="getList" @cancel="cancel" name="高级筛选">
              <el-form
                style="width: 300px;"
                label-width="80px"
              >
                <el-form-item label="账期："> 
                  <qzf-search-period v-model:period="listQuery.beginPeriod"></qzf-search-period> &nbsp;-&nbsp;
                  <qzf-search-period v-model:period="listQuery.endPeriod"></qzf-search-period>
                </el-form-item>
                <el-form-item label="日期："> 
                  <el-date-picker
                    v-model="listQuery.beginDate"
                    type="date"
                    placeholder="开始时间"
                    value-format="YYYY-MM-DD"
                    size="small"
                    style="width: 100px"
                  /> &nbsp;-&nbsp;
                  <el-date-picker
                    v-model="listQuery.endDate"
                    type="date"
                    placeholder="结束时间"
                    value-format="YYYY-MM-DD"
                    size="small"
                    style="width: 100px"
                  />
                </el-form-item>
              </el-form>
            </search>
          </div>
          <div style="height: 30px;display: flex;align-items: center;">
            <el-button
              @click="uplode"
              type="primary"
              size="small"
              icon="Upload"
              >上传</el-button
            >
            <el-button
              @click="down"
              type="primary"
              size="small"
              icon="Download"
              >下载</el-button
            >
            <el-button
              @click="del"
              type="primary"
              size="small"
              icon="DeleteFilled"
              >删除</el-button
            >
          </div>
        </div>
        <el-table
          :data="list"
          border
          style="width: 100%"
          v-loading="loading"
          :height="contentStyleObj"
          @selection-change="handleSelectionChange"
          stripe
        >
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column align="center" type="selection" width="55"> </el-table-column>
          <el-table-column prop="fileName" label="文件名称" width="400" fixed>
          </el-table-column>
          <el-table-column prop="uploader" label="上传人" width="160" fixed>
          </el-table-column>
          <el-table-column prop="uploadDate"  label="上传日期" align="center" width="150">
          </el-table-column>
          <el-table-column prop="source"  label="来源" align="center" width="150">
          </el-table-column>
          <el-table-column prop="eType"  label="类型" align="center" width="150">
          </el-table-column>
          <el-table-column prop="uploadPeriod"   label="上传账期" align="center" width="150">
          </el-table-column>
          <el-table-column prop="count" label="操作" align="center">
            <template #default="{ row }">
              <el-button
                size="small"
                type="primary"
                @click="edit(row.id)"
                >修改文件名</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
  <div class="pagination">
    <qzf-pagination
      v-show="total > 0"
      :total="total"
      v-model:page="listQuery.page"
      v-model:limit="listQuery.pageSize"
      @pagination="getList"
    />
  </div>

  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    title="上传"
    v-model="uplodeShow"
    width="26%"
  >
    <el-form size="small" :model="from" :rules="rules" ref="ruleFormRef" label-position="right"   label-width="80px">
      <div>
        <el-form-item label="上传账期" prop="uploadPeriod">
        <qzf-search-period
          v-model:period="from.uploadPeriod"
        ></qzf-search-period>
      </el-form-item>
      </div>
      <!-- <el-form-item label="来源" prop="source">
        <el-select
          v-model="from.source"
          placeholder="请选择来源"
          filterable
          clearable
          size="small"
          style="width: 250px;margin: 0;"
        >
          <el-option
            v-for="item in sourceList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="类型" prop="eType">
        <el-select
          v-model="from.eType"
          placeholder="请选择类型"
          filterable
          clearable
          size="small"
          style="width: 250px;margin: 0;"
        >
          <el-option
            v-for="item in eTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="上传日期" prop="uploadDate">
        <el-date-picker
          v-model="from.uploadDate"
          type="date"
          placeholder="请选择客户确认时间"
          value-format="YYYY-MM-DD"
          style="width: 250px"
        />
      </el-form-item> -->
      <!-- <el-form-item label="上传人" prop="uploader">
        <el-input
          v-model="from.uploader"
          placeholder="上传人"
          class="w250"
        ></el-input>
      </el-form-item> -->
      <!-- <FileUploader @change="updateFileList" :limit="true" :initialFileList="from.files" /> -->
      <el-form-item >
        <div style="display: flex;margin-left: -70px;">
          <div style="width: 70px;font-size: 13px;height: 10px;margin-top: -3px;"><span style="color: rgb(243 113 113);margin-right: 3px;">*</span>文件上传</div>
          <el-input style="width: 250px;margin-right:10px;" size="small" v-model="path"></el-input>
          <input size="small" ref="excelUpload" class="excel-upload-input" style="display:none" type="file" accept=".xlsx, .xls, .xml" @change="handleClick">
          <el-button type="primary" @click="handleUpload()" size="small">请选择文件</el-button>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="uplodeShow = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="save"
          :loading="saveLoading"
          >保存</el-button
        >
      </span>
    </template>
  </el-dialog>

</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import store from '../../store';
import { accEFile,getAccEFile,accEFileDel,accEFileEdit,getCounts } from "@/api/company";
import { ElMessage,ElMessageBox  } from "element-plus";
import { currentAccountPeriod } from "@/utils";
import FileUploader from "@/components/uploadImg/FileUploader.vue";
import { get } from "sortablejs";
const { proxy } = getCurrentInstance();
const ruleFormRef = ref();
const excelUpload = ref();
const contentStyleObj = ref({});//列表高度
const list = ref([]);//列表数据
const numAll = ref(0);//列表数据
const treeDataList = ref([
  {
    label: "所有文件",
    children:[
      {label:"发票", children: []},
      {label:"银行", children: []},
      {label:"薪酬", children: []},
      {label:"其他", children: []},
    ]
  }
]);//列表数据
const total = ref(0);//列表数据
const sels = ref([]);//列表数据
const ids = ref([]);//列表数据
const countTemp = ref({});//列表数据
const loading = ref(false);//列表loading
const countloading = ref(false);//列表loading
const uplodeShow = ref(false);//上传弹窗
const saveLoading = ref(false);//保存按钮loading
const clicked = ref(false);//保存按钮loading
const path = ref('');//保存按钮loading
const rawFile = ref({});//保存按钮loading
const defaultProps = {
  children: 'children',
  label: 'label',
}
const sourceList = ref([
  {
    value: 'pc',
    label: '电脑端',
  },
  {
    value: 'mobile',
    label: '手机端',
  },
]);
const eTypeList = ref([
  {
    value: 'bank',
    label: '银行',
  },
  {
    value: 'salary',
    label: '薪酬',
  },
  {
    value: 'bill',
    label: '发票',
  },
  {
    value: 'others',
    label: '其他',
  },
]);
const rules = ref({
  uploadDate: [{ required: true, message: "请选择上传日期", trigger: "change" }],
  uploadPeriod: [{ required: true, message: "请选择上传账期", trigger: "change" }],
  source: [{ required: true, message: "请选择来源", trigger: "change" }],
  eType: [{ required: true, message: "请选择类型", trigger: "change" }],
});
const from = ref({
  files: [],
  uploader: store.getters['user/user'].cnName,
  uploadDate:'',
  uploadPeriod: store.getters["user/comInfo"].period,
  source:'',
  eType:'',
});//表单
const listQuery = ref({
  page: 1,
  pageSize: 20,
  fileName:'',
  eType: '',
  beginPeriod:'',
  endPeriod:'',
  beginDate: '',
  endDate: '',
  // beginPeriod: currentAccountPeriod(),
  // endPeriod: currentAccountPeriod(),
  // period: currentAccountPeriod(),
});
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(250);
  getCountsNum()
  getList();
});
const getList = () => {
  console.log(store.getters["user/comInfo"].period)
  loading.value = true;
  getAccEFile(listQuery.value).then((res) =>{
    loading.value = false;
    if(res.data.msg == 'success'){
      list.value = res.data.data.list
      total.value = res.data.data.total
      if(list.value.length > 0){
        list.value.forEach((file) =>{
          file.uploadDate = file.uploadDate.split('T')[0]
        })
      }
    }
  })
};
const clickType = (item) => {
  if(item == 'sy'){
    listQuery.value.eType = ''
  }else{
    listQuery.value.eType = item
  }
  getList()
};
const getCountsNum = () => {
  countloading.value = true;
  getCounts(listQuery.value).then((res) =>{
    if(res.data.msg == 'success'){
      countTemp.value = res.data.data
      numAll.value = countTemp.value.发票+countTemp.value.银行+countTemp.value.薪酬+countTemp.value.其他
    }
    countloading.value = false
  })
};
// 上传
const uplode = () =>{
  reset()
  uplodeShow.value = true
  //获取当前时间
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 使用padStart确保月份是两位数
  const day = String(currentDate.getDate()).padStart(2, '0'); // 使用padStart确保日期是两位数
  const formattedDate = `${year}-${month}-${day}`;
  from.value.uploadDate = formattedDate
}
const edit = (e) =>{
  ElMessageBox.prompt('修改文件名', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    inputPattern:
    /.+/,
    inputErrorMessage: '请填写文件名',
  })
    .then(({ value }) => {
      accEFileEdit(e,{name:value}).then((res) => {
        console.log(res.data);
        if(res.data.msg =='success'){
          ElMessage.success("修改成功");
          getList();
        }
      })
    })
    .catch(() => {
      // ElMessage({
      //   type: 'info',
      //   message: 'Input canceled',
      // })
    })
}
// 下载
const down = () =>{
  if (sels.value.length == 0) {
    ElMessage.warning("请选择文件");
    return;
  }
  let url =  "https://file.listensoft.net" + sels.value[0].filePath;
  window.open(url);
}
// 删除
const del = () =>{
  if (sels.value.length == 0) {
    ElMessage.warning("请选择文件");
    return;
  }
  ElMessageBox.confirm(
    '确定要删除文件',
    '删除',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: '确定',
    }
  )
    .then(() => {
      accEFileDel({ids:ids.value}).then((res) => {
        if(res.data.msg =='success'){
          ElMessage.success("删除成功");
          getList();
        }
      })
    })
    .catch(() => {
    })
}
// 下载
const handleClick = (e) =>{
  const files = e.target.files;
  rawFile.value = files[0]; // only use files[0]
  path.value = e.target.value; //路径
}
// 删除
const handleUpload = () =>{
  excelUpload.value.click();
}
const reset = () =>{
  rawFile.value = {}
  path.value = ''
  from.value = {
    files: [],
    uploader: store.getters['user/user'].cnName,
    uploadDate:'',
    uploadPeriod: store.getters["user/comInfo"].period,
    source:'',
    eType:'',
  }
}
const cancel = () =>{
  listQuery.value ={
    page: 1,
    pageSize: 20,
    fileName:'',
    eType: '',
    beginPeriod:'',
    endPeriod:'',
    beginDate: '',
    endDate: '',
  }
  getList()
}
const updateFileList = (e) => {
  // from.value.files = e
  // from.value.file = from.value.files[0].name
  // console.log(from.value);
};
// 删除
const handleSelectionChange = (e) =>{
  sels.value = e;
  ids.value = sels.value.map((v) => v.id)
}
// 删除
const save = () =>{
  if (!path.value) {
    ElMessage.warning("请选择文件");
    return;
  }
  const files = rawFile.value;
  const isLt1M = files.size / 1024 / 1024 < 10;
  if (!isLt1M) {
    ElMessage.warning("请不要上传大于10M的文件!");
    return;
  }
  const param = new FormData(); // 创建form对象
  param.append("file", rawFile.value);
  param.append("uploader", from.value.uploader);
  param.append("uploadDate", from.value.uploadDate);
  param.append("uploadPeriod", from.value.uploadPeriod);
  param.append("source", 'pc');
  param.append("eType", from.value.eType);
  console.log(param);
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      // 判断上传的账期是否跟账簿内一致
      if(from.value.uploadPeriod != store.getters["user/comInfo"].period){
        ElMessageBox.confirm(
        '上传账期与当前账簿内帐期不一致，是否确认上传?',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          saveLoading.value = true; // 防止多次点击
          accEFile(param).then((res) => {
            if (res.data.msg == "success") {
              ElMessage.success("保存成功");
              uplodeShow.value = false;
              saveLoading.value = false;
              getList();
            }
          });
        })
        .catch(() => {

        })
      }else{
        saveLoading.value = true; // 防止多次点击
        accEFile(param).then((res) => {
          if (res.data.msg == "success") {
            ElMessage.success("保存成功");
            uplodeShow.value = false;
            saveLoading.value = false;
            getList();
          }
        });
      }
    }
  });
}

</script>
<script>
export default {
  name: "progressTask",
};
</script>
<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.w250{
  width: 250px;
}
.pagination {
  margin-top: 16px;
  float: right;
}
</style>

